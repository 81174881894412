

























import { Usuario } from '@/models';
import {Vue,Component, Prop, Watch} from 'vue-property-decorator'
import BuscaDeVendedor from './BuscaDeVendedor.vue';

@Component({
	components: {
		BuscaDeVendedor,
	},
})

export default class DialogoDeBuscaDeVendedor extends Vue {
	@Prop({ type: Boolean, default: false }) filtrarSomenteVendedorAtivo!: boolean
	@Prop({ type: Boolean, default: false }) filtrarSomenteUsuariosAtivos!: boolean
	usuario: Usuario | null = null
	mostra = false

	@Watch('usuario')
	onChangeUsuario(usuario: Usuario | null){
		if (!usuario) return
		this.$emit('input', usuario)
		this.mostra = false
		this.$nextTick(() => {
			this.usuario = null
		})
	}
}

