


















import Component from 'vue-class-component';
import { Vue } from 'vue-property-decorator'
import MenuDeFiltrosDeUsuario from '../usuario/MenuDeFiltroDeUsuario.vue';

@Component({
	components: {
		MenuDeFiltrosDeUsuario,
	},
})

export default class DialogoDeEdicaoDeMetas extends Vue {
	mostraDialogo = false
}
