import { FiltroDeBuscaDeMetas, Meta } from "@/models/Meta";


export function criarMeta(): Meta {
	return {
		id: '',
		vendedorAtribuido: [],
		ativarMeta: false,
		descricao: '',
		comissao: 0,
		valor: 0,
		tags: [],
		lojaId: '',
	}
}

export function obterFiltroDeBuscaDeMetasDefault(): Pick<Required<FiltroDeBuscaDeMetas>, 'idVendedorAtribuido' | 'descricao' | 'valor' | 'lojaId'> {
	return {
		idVendedorAtribuido: '',
		descricao: '',
		valor: 0 ,
		lojaId: '',
	}
} 