
import type { Meta } from "@/models";
import createSearchTags from "@/shareds/createSearchTags";
import {  getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from "..";

@Module({
	name: 'MetaStore',
	namespaced: true,
	dynamic: true,
	store,
})
export default class MetaStore extends VuexModule{
	busca = ''
	metas: Meta[] = []
	metaSelecionada: Meta | null = null
	atualizarFiltros = false

	@Mutation
	adicionaMeta(meta: Meta) {
		this.metas.push(meta)
	}

	@Mutation
	atualizaMeta(meta: Meta) {
		const userIndex = this.metas.findIndex(
			element => element.id === meta.id,
		)
		this.metas.splice(userIndex, 1, meta)
	}

	@Mutation
	atualizarFiltro(filtrar: boolean) {
		this.atualizarFiltros = filtrar
	}

	@Mutation
	setMetas(metas: Meta[]) {
		this.metas = metas
	}

	@Mutation
	setMetaSelecionada(meta: Meta) {
		this.metaSelecionada = meta
	}


	get metasEmOrdemAlfabetica() {
		return [...this.metas].sort((metaA, MetaB) =>
			metaA.descricao.localeCompare(MetaB.descricao),
		)
	}

	get metasFiltradas() {
		const tags = createSearchTags(this.busca)
		return this.metasEmOrdemAlfabetica.filter(meta => 
			tags.every(tagAPesquisar =>
				meta.tags && meta.tags.some(tag => tag.includes(tagAPesquisar)),
			),
		)
	}

	get filtrar() {
		return this.atualizarFiltros
	}
	
	get metaAtual() {
		return this.metaSelecionada
	}

}
export const MetaModule = getModule(MetaStore)